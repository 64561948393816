import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, concat, forkJoin, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Computed, Persistence, StateRepository } from '@angular-ru/ngxs/decorators';
import { State } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep } from 'lodash-es';

import {
  AddBingoStateInterface,
  NewBingoInformationInterface,
  NewBingoResponseInterface,
  QuestionsBingoInterface,
  StepAddBingo,
} from './add-bingo.interfaces';
import { appendFormData } from '@helpers/attachments-form-data';
import { getFullUrtForFile } from '@helpers/getFullUrtForFile';
import { LogoutService } from '@state/services/logout.service';

@Persistence()
@StateRepository()
@State({
  name: 'eng_add_bingo',
  defaults: {},
})
@Injectable({
  providedIn: 'any',
})
@UntilDestroy()
export class AddBingoState extends NgxsDataRepository<AddBingoStateInterface> {
  constructor(
    private httpClient: HttpClient,
    private logoutService: LogoutService,
  ) {
    super();
    this.logoutService.logout$.pipe(untilDestroyed(this)).subscribe(() => this.reset());
  }

  @Computed()
  get newBingoInformation(): NewBingoInformationInterface | null {
    return this.snapshot.newBingoInformation ?? null;
  }

  @Computed()
  get newBingoOptions(): QuestionsBingoInterface[] | null {
    return this.snapshot.newBingoOptions ?? null;
  }
  @Computed()
  get lastBingoOptions(): QuestionsBingoInterface[] | null {
    return this.snapshot.lastQuestions ?? null;
  }

  @Computed()
  get currentStep(): StepAddBingo {
    if (this.snapshot.step) {
      return this.snapshot.step;
    }
    this.setStep(StepAddBingo.information);
    return StepAddBingo.information;
  }

  // @DataAction()
  addInformation(
    newBingoInformation: NewBingoInformationInterface,
  ): Observable<NewBingoResponseInterface> {
    const id: number | undefined = this.snapshot?.newBingoInformation?.id;
    this.ctx.setState((state) => ({
      ...state,
      newBingoInformation: {
        id,
        ...newBingoInformation,
      },
    }));
    if (id) {
      const newBingoInformationClone = cloneDeep(newBingoInformation);
      // // @ts-ignore
      // newBingoInformationClone.card_length = undefined;
      // // @ts-ignore
      // newBingoInformationClone.card_width = undefined;
      newBingoInformationClone.imgUrl = undefined;
      if (typeof newBingoInformationClone.image === 'string') {
        newBingoInformationClone.image &&= undefined;
      }
      newBingoInformationClone.type = undefined;
      const formData = new FormData();
      appendFormData(formData, newBingoInformationClone, '');
      return this.httpClient
        .post<NewBingoResponseInterface>(`{api}/games/${id}/update`, formData)
        .pipe(
          tap((_newBingo) =>
            this.ctx.setState((state) => ({
              ...state,
              newBingoInformation: {
                ...state.newBingoInformation,
                title: newBingoInformation.title,
                description: newBingoInformation.description,
                id,
              },
            })),
          ),
        );
    }
    const newBingoInformationClone = cloneDeep(newBingoInformation);
    newBingoInformationClone.imgUrl = undefined;
    const formData = new FormData();
    appendFormData(formData, newBingoInformationClone, '');
    return this.httpClient.post<NewBingoResponseInterface>('{api}/games', formData).pipe(
      tap((newBingo) =>
        this.ctx.setState((state) => ({
          ...state,
          newBingoInformation: {
            ...state.newBingoInformation,
            id: newBingo.id,
          },
        })),
      ),
    );
  }

  @Computed()
  get isChangeLength(): boolean {
    return this.snapshot?.isChangeLength ?? false;
  }

  // @DataAction()
  saveNewBingoOptions(
    questions: QuestionsBingoInterface[],
    lastQuestions: QuestionsBingoInterface[],
    isChangeLength: boolean = false,
  ) {
    this.ctx.setState((state) => ({
      ...state,
      newBingoOptions: questions,
      lastQuestions: lastQuestions,
      isChangeLength,
    }));
  }

  // @DataAction()
  // addQuestions(gameID: number, questions: QuestionsBingoInterface[]): Observable<any[]> {
  //   this.setState((state) => ({
  //     ...state,
  //     newBingoOptions: questions,
  //   }));
  //   const response: any[] = [];
  // questions.forEach((question) => {
  //   const _question = cloneDeep(question);
  //   if (typeof _question.description.image === 'string') {
  //     _question.description.image &&= undefined;
  //   }
  //   if (typeof _question.answer.image === 'string') {
  //     _question.answer.image &&= undefined;
  //     _question.answer.type &&= undefined;
  //   }
  //
  //   _question.description.imgUrl &&= undefined;
  //   _question.answer.imgUrl &&= undefined;
  //   const formData = new FormData();
  //   appendFormData(formData, _question, '');
  //
  //   if (_question?.id) {
  //     response.push(
  //       this.httpClient.patch<void>(`{api}/games/${gameID}/questions/${_question.id}`, formData),
  //     );
  //     return;
  //   }
  //   response.push(this.httpClient.post<void>(`{api}/games/${gameID}/questions`, formData));
  // });
  //   return (forkJoin(response) as Observable<{ id: number }[]>).pipe(
  //     tap((questionsId) => {
  //       questionsId.forEach((_questionId, index) => {
  //         let i = 0;
  //         this.setState(
  //           patch({
  //             newBingoOptions: updateItem<QuestionsBingoInterface>(
  //               (newBingoOption) => {
  //                 let foundItem = i === index;
  //                 i++;
  //                 return foundItem;
  //               },
  //               {
  //                 ...this.snapshot.newBingoOptions[index],
  //                 id: _questionId.id,
  //               },
  //             ),
  //           }),
  //         );
  //       });
  //     }),
  //   );
  // }

  // @DataAction()
  addQuestionsAll(
    gameID: number,
    questions: QuestionsBingoInterface[],
    _ignoredIsChangeLength: boolean = false,
  ): Observable<any[]> {
    let isNew = true;
    this.ctx.setState((state) => ({
      ...state,
      newBingoOptions: questions,
    }));
    let response: Observable<QuestionsBingoInterface[]>;
    const formData = new FormData();
    const _questions = cloneDeep(questions);
    _questions.forEach((_question) => {
      if (isNew) {
        isNew = !_question.id;
      }
      // if (typeof _question.description.image === 'string') {
      // _question.description.image &&= undefined;
      // }
      _question.answers.forEach((answer) => {
        if (typeof answer.image === 'string') {
          answer.image &&= undefined;
        }
        answer.imgUrl &&= undefined;
        answer.is_correct = answer.is_correct ? 1 : 0;
      });

      _question.description.imgUrl &&= undefined;
    });
    appendFormData(formData, _questions, 'questions');
    if (isNew) {
      response = this.httpClient.post<QuestionsBingoInterface[]>(
        `{api}/games/${gameID}/questions/all`,
        formData,
      );
    } else {
      appendFormData(formData, { _method: 'PATCH' }, '');
      response = this.httpClient.post<QuestionsBingoInterface[]>(
        `{api}/games/${gameID}/questions/all`,
        formData,
      );
    }

    return response.pipe(
      tap((questionsBingo: QuestionsBingoInterface[]) => {
        this.ctx.setState(
          patch({
            newBingoOptions: questionsBingo,
          }),
        );
      }),
    );
  }

  // @DataAction()
  preChangeQuestionsAll(gameID: number, questions: QuestionsBingoInterface[]) {
    const _questions = cloneDeep(questions);
    const images: { indexDesc: number; indexAnsw?: number; url: string; type: string }[] = [];
    _questions.forEach((_question, indexDesc) => {
      if (typeof _question.description.image === 'string') {
        images.push({
          indexDesc,
          url: _question.description.image,
          type: 'description',
        });
      }
      _question.answers.forEach((answer, indexAnsw) => {
        if (typeof answer.image === 'string') {
          images.push({
            indexDesc,
            indexAnsw,
            url: answer.image,
            type: 'answers',
          });
        }
        answer.imgUrl &&= undefined;
        answer.is_correct = answer.is_correct ? 1 : 0;
      });

      _question.description.imgUrl &&= undefined;
    });
    return { questions: _questions, images: images };
  }

  // @DataAction()
  preChangeFileQuestionsAll(
    images: any,
    questions: QuestionsBingoInterface[],
  ): Observable<QuestionsBingoInterface[]> {
    if (!images.length) {
      return of(questions);
    }
    return forkJoin(images.map((image: any) => this.getImage(getFullUrtForFile(image.url)))).pipe(
      map((imagesBlod: any) => {
        imagesBlod.map((imageBlod: any, index: number) => {
          const image = images[index];
          if (image.type === 'description') {
            questions[image.indexDesc].description.image = imageBlod;
          }
          if (image.type === 'answers') {
            questions[image.indexDesc].answers[image.indexAnsw ?? 0].image = imageBlod;
          }
        });
        return questions;
      }),
    );
  }

  // @DataAction()
  changeQuestionsAll(gameID: number, questions: QuestionsBingoInterface[]): Observable<any[]> {
    this.ctx.setState((state) => ({
      ...state,
      newBingoOptions: questions,
    }));

    const newQuestions = questions.filter((question) => !(question?.id || question?.id === 0));
    const questionsForUpdate = questions.filter((question) => question?.id || question?.id === 0);

    const responses = [];

    if (questionsForUpdate.length) {
      const formData = new FormData();
      questionsForUpdate.forEach((_question) => {
        if (typeof _question.description.image === 'string') {
          _question.description.image &&= undefined;
        }
        _question.answers.forEach((answer) => {
          if (typeof answer.image === 'string') {
            answer.image &&= undefined;
          }
          answer.imgUrl &&= undefined;
          answer.is_correct = answer.is_correct ? 1 : 0;
        });

        _question.description.imgUrl &&= undefined;
      });
      appendFormData(formData, questionsForUpdate, 'questions');

      appendFormData(formData, { _method: 'PATCH' }, '');
      const response = this.httpClient.post<QuestionsBingoInterface[]>(
        `{api}/games/${gameID}/questions/all`,
        formData,
      );
      responses.push(response);
    }

    if (newQuestions.length) {
      const formData = new FormData();
      appendFormData(formData, newQuestions, 'questions');
      const response: Observable<QuestionsBingoInterface[]> = this.httpClient.post<
        QuestionsBingoInterface[]
      >(`{api}/games/${gameID}/questions/all`, formData);
      responses.push(response);
    }

    return forkJoin(responses).pipe(
      tap((questionsBingo: QuestionsBingoInterface[][]) => {
        const newQuestionsBingo = [];
        if (questionsBingo.length >= 1) {
          newQuestionsBingo.push(...questionsBingo[0]);
        }
        if (questionsBingo.length >= 2) {
          newQuestionsBingo.push(...questionsBingo[1]);
        }
        this.ctx.setState(
          patch({
            newBingoOptions: newQuestionsBingo,
          }),
        );
      }),
    );
  }

  getImage(imageUrl: string): Observable<Blob> {
    return this.httpClient.get(imageUrl, { responseType: 'blob' });
  }

  // @DataAction()
  addQuestion(gameID: number, question: QuestionsBingoInterface): Observable<void> {
    return this.httpClient.post<void>(`{api}/games/${gameID}/questions`, question);
  }

  // @DataAction()
  setStep(step: StepAddBingo): void {
    return this.ctx.setState((state) => ({ ...state, step }));
  }

  // @DataAction()
  updateGame(
    newBingoInformation: NewBingoInformationInterface,
  ): Observable<NewBingoResponseInterface> {
    this.ctx.setState((state) => ({ ...state, newBingoInformation }));
    return this.httpClient
      .post<NewBingoResponseInterface>(
        `{api}/games/${newBingoInformation.id}/update`,
        newBingoInformation,
      )
      .pipe(
        tap((newBingo) =>
          this.ctx.setState((state) => ({
            ...state,
            newBingoInformation: {
              ...state.newBingoInformation,
              id: newBingo.id,
            },
          })),
        ),
      );
  }

  // @DataAction()
  getGame(gameID: any): Observable<any> {
    const game = this.httpClient.get<NewBingoInformationInterface>(`{api}/games/${gameID}`).pipe(
      tap((newBingoInformation: NewBingoInformationInterface) =>
        this.ctx.setState((state) => ({
          ...state,
          newBingoInformation,
        })),
      ),
    );

    const questions = this.httpClient
      .get<QuestionsBingoInterface[]>(`{api}/games/${gameID}/questions`)
      .pipe(
        tap((newBingoOptions: QuestionsBingoInterface[]) =>
          this.ctx.setState((state) => ({
            ...state,
            newBingoOptions,
          })),
        ),
      );
    return concat(game, questions);
  }

  // @DataAction()
  preDeleteQuestion(
    gameId: number,
    questions: QuestionsBingoInterface[],
    newBingoOptions: QuestionsBingoInterface[],
  ) {
    const ids: string[] = newBingoOptions
      ?.slice(questions.length)
      .map((question) => question.id!.toString());
    if (!ids.length) {
      return of([]);
    }
    const idsN: any = {};
    ids.map((id, index) => {
      idsN[`ids[${index}]`] = id.toString();
    });

    return this.httpClient.delete(`{api}/games/${gameId}/questions/list`, { params: idsN });
  }

  deleteQuestion(gameId: number, qId: number) {
    return this.httpClient.delete(`{api}/games/${gameId}/questions/${qId}`);
  }

  deleteGame(gameID: number | undefined) {
    return this.httpClient.delete<any>(`{api}/games/${gameID}`);
  }
}
