import { isArray } from 'lodash-es';
import { Observable } from 'rxjs';

export interface formData {
  name: string;
  url: string;
}

export const attachmentsFormData = (attachments: formData | formData[]): FormData => {
  const formData = new FormData();
  if (!isArray(attachments)) {
    attachments = [attachments];
  }
  attachments.forEach((attachment) => {
    const file = dataURIToBlob(attachment.url);
    formData.append(attachment.name, file);
  });
  return formData;
};

const dataURIToBlob = (dataURI: string): Blob => {
  const splitDataURI = dataURI.split(',');
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const dataUrlToObs = (myFile: File) =>
  new Observable<string>((subscriber) => {
    const reader = new FileReader();
    reader.readAsDataURL(myFile);
    reader.onload = () => {
      subscriber.next(reader.result as string);
      subscriber.complete();
    };
    reader.onerror = () => subscriber.error(reader.error);
    return () => reader.abort();
  });
const blobToFile = (theBlob: Blob, fileName: string): File => {
  return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type });
};

export const appendFormData = (formData: FormData, data: any, rootName: string) => {
  const root = rootName || '';
  if (data instanceof File || data instanceof Blob) {
    if (data instanceof Blob) {
      formData.append(root, blobToFile(data, 'img'));
    } else {
      formData.append(root, data);
    }
  } else if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      appendFormData(formData, data[i], root + '[' + i + ']');
    }
  } else if (typeof data === 'object' && data) {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        if (root === '') {
          appendFormData(formData, data[key], key);
        } else {
          appendFormData(formData, data[key], root + '[' + key + ']');
        }
      }
    }
  } else {
    if (data !== null && typeof data !== 'undefined') {
      formData.append(root, data);
    }
  }
};

export const getFileByBase64 = (imgBase64: string, filename: string = 'file'): File => {
  const arr = imgBase64.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};
